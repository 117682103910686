<script setup lang="ts">
import { useApiOfflineGetBusDossier } from '~/apiClient';

// PROPS
const props = defineProps({
    id: { type: String, required: true },
});

// COMPOSABLES
const { data } = useApiOfflineGetBusDossier(props.id);
</script>

<template>
    <NuxtLink :to="{ name: 'bus-dossiers-id', params: { id: id } }" />
    <NuxtLink :to="{ name: 'bus-dossiers-id-images', params: { id: id } }" />
    <NuxtLink :to="{ name: 'bus-dossiers-id-details', params: { id: id } }" />
    <NuxtLink :to="{ name: 'bus-dossiers-id-documents', params: { id: id } }" />
    <UIcon v-if="data?.owner?.countryCode" :name="`i-circle-flags-${data.owner.countryCode.toLowerCase()}`" class="hidden" />
</template>
